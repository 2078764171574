.abouttimeline-container1 {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
.abouttimeline-container2 {
  flex: 0 0 auto;
  width: 100%;
  height: 400px;
  display: flex;
  align-items: center;
  background-size: cover;
  justify-content: center;
  background-image: url("/6513d74e9b7ca76d70274ee327f06d3b.jpg");
  background-position: top;
}
.abouttimeline-container3 {
  flex: 0 0 auto;
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
}
.abouttimeline-text12 {
  display: inline-block;
}
.abouttimeline-text13 {
  display: inline-block;
  text-align: center;
}
.abouttimeline-text14 {
  display: inline-block;
}
.abouttimeline-text15 {
  display: inline-block;
}
.abouttimeline-text18 {
  display: inline-block;
  text-align: center;
}
.abouttimeline-text26 {
  display: inline-block;
  text-align: center;
}
.abouttimeline-text30 {
  color: rgb(255, 255, 255);
  margin: var(--dl-space-space-threeunits);
  display: inline-block;
  padding: var(--dl-space-space-unit);
  text-align: center;
  background-color: rgba(8, 8, 8, 0.78);
}
.abouttimeline-text31 {
  color: rgb(255, 255, 255);
  display: inline-block;
  padding: var(--dl-space-space-unit);
  font-size: 26px;
  background-color: rgb(0, 0, 0);
}

@media(max-width: 991px) {
  .abouttimeline-container1 {
    background-color: #ffffff;
  }
  .abouttimeline-text30 {
    color: #ffffff;
    padding: var(--dl-space-space-twounits);
    text-align: center;
    background-color: rgba(2, 2, 2, 0.86);
  }
}
@media(max-width: 420px) {
  .abouttimeline-text30 {
    color: #ffffff;
    margin: var(--dl-space-space-unit);
    padding: var(--dl-space-space-unit);
    text-align: center;
    background-color: rgba(8, 8, 8, 0.73);
  }
  .abouttimeline-text31 {
    font-size: 16px;
    text-align: center;
  }
}
