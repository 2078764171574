.test-container1 {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.test-max-width {
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.test-container2 {
  gap: var(--dl-space-space-unit);
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.test-container3 {
  flex: 1;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-theme-neutral-light);
}
.test-image1 {
  width: 261px;
  height: 232px;
}
.test-container4 {
  flex: 1;
  display: flex;
  position: relative;
  align-items: center;
  border-radius: var(--dl-radius-radius-cardradius);
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-theme-neutral-light);
}
.test-image2 {
  width: 304px;
  height: 182px;
}
.test-container5 {
  gap: var(--dl-space-space-unit);
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.test-container6 {
  flex: 1;
  display: flex;
  position: relative;
  align-items: center;
  border-radius: var(--dl-radius-radius-cardradius);
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-theme-neutral-light);
}
.test-video {
  width: 320px;
  height: 180px;
}
.test-text16 {
  display: inline-block;
}
.test-text17 {
  display: inline-block;
}
.test-text18 {
  display: inline-block;
  text-align: center;
}
.test-text19 {
  display: inline-block;
  text-align: center;
}
.test-text23 {
  display: inline-block;
  text-align: center;
}
.test-text31 {
  display: inline-block;
}
.testroot-class-name {
  background-color: #000000;
}
@media(max-width: 991px) {
  .test-max-width {
    flex-direction: column;
  }
}
@media(max-width: 767px) {
  .test-container2 {
    flex-direction: column;
  }
  .test-container3 {
    width: 100%;
  }
  .test-container4 {
    width: 100%;
  }
  .test-container5 {
    flex-direction: column;
  }
  .test-container6 {
    width: 100%;
  }
}
