.speical-feature-container {
  width: 100vw;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.speical-feature-iframe {
  width: auto;
  height: auto;
}
