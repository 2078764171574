.error-container {
  width: 100vw;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.error-container1 {
  flex: 0 0 auto;
  width: 100%;
  border: 2px dashed rgba(120, 120, 120, 0.4);
  height: 100px;
  display: flex;
  align-items: flex-start;
}
.error-container2 {
  width: 272px;
  height: 260px;
  display: flex;
  background-size: cover;
  background-image: url("/external/hamlogo115-yjtf-300h.png");
  background-position: center;
}
.error-text {
  align-self: center;
  text-align: center;
}
.error-text1 {
  text-transform: uppercase;
}
.error-text2 {
  text-transform: uppercase;
}
.error-text3 {
  text-transform: uppercase;
}
@media(max-width: 991px) {
  .error-container2 {
    width: 174px;
    height: 156px;
  }
}
