.dt-footer-container {
  width: 1170px;
  height: 109px;
  display: flex;
  position: relative;
  align-items: center;
  padding-top: 1px;
  flex-direction: row;
  justify-content: center;
}
.dt-footer-container1 {
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  border-color: #000000;
  border-style: dashed;
  border-width: 2px;
  justify-content: center;
  background-color: #000000;
}
.dt-footer-container2 {
  width: 121px;
  height: 112px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
}
.dt-footer-image {
  width: 113px;
  height: 111px;
  object-fit: cover;
}
.dt-footer-container3 {
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  border-color: #000000;
  border-style: solid;
  border-width: 2px;
  justify-content: center;
  background-color: #000000;
}
.dt-footer-social-bar {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  padding-bottom: 48px;
  justify-content: center;
}
.dt-footer-icon {
  fill: #fff8f8;
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.dt-footer-icon02 {
  fill: #D9D9D9;
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.dt-footer-link {
  display: contents;
}
.dt-footer-icon04 {
  fill: #D9D9D9;
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
  text-decoration: none;
}
.dt-footer-icon06 {
  fill: #D9D9D9;
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.dt-footer-link1 {
  display: contents;
}
.dt-footer-icon08 {
  fill: #D9D9D9;
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  text-decoration: none;
}
.dt-footer-root-class-name {
  left: 0px;
  width: 100%;
  bottom: 0px;
  position: static;
}
.dt-footer-root-class-name1 {
  left: 0px;
  width: 100%;
  bottom: 0px;
  position: absolute;
}
.dt-footer-root-class-name2 {
  left: 0px;
  width: 100%;
  bottom: 0px;
  height: var(--dl-size-size-large);
  position: static;
  align-self: center;
}
.dt-footer-root-class-name3 {
  left: 0px;
  width: 100%;
  bottom: 0px;
  position: absolute;
}
.dt-footer-root-class-name4 {
  flex: 1;
  left: 15px;
  width: 100%;
  bottom: 0px;
  position: absolute;
}
.dt-footer-root-class-name5 {
  left: 0px;
  width: 100%;
  bottom: 0px;
  height: var(--dl-size-size-large);
  position: static;
  align-self: center;
}
.dt-footer-root-class-name6 {
  left: 15px;
  bottom: 0px;
  position: absolute;
}
@media(max-width: 991px) {
  .dt-footer-image {
    width: 190px;
    height: 100%;
  }
  .dt-footer-icon {
    width: 32px;
    height: 32px;
  }
  .dt-footer-icon02 {
    display: none;
  }
  .dt-footer-icon04 {
    width: 32px;
    height: 32px;
  }
  .dt-footer-icon06 {
    display: none;
  }
  .dt-footer-icon08 {
    width: 32px;
    height: 32px;
  }
  .dt-footer-root-class-name {
    width: 100%;
  }
  .dt-footer-root-class-name1 {
    width: 100%;
  }
  .dt-footer-root-class-name4 {
    left: 0px;
    width: 100%;
    bottom: 0px;
    position: absolute;
    align-self: stretch;
  }
}
@media(max-width: 767px) {
  .dt-footer-social-bar {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
}
@media(max-width: 479px) {
  .dt-footer-social-bar {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
}
