.WTFNewsFeaturedImg{
  width: 100%;
  height: 288px;
  object-fit: cover;
  object-position: center;
  padding: 10px;
  
}
.wtf-news-container {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.wtf-news-header {
  text-align: center;
  margin-bottom: 40px;
}

.wtf-news-header h1 {
  font-size: 2.5rem;
  color: #333;
}

.wtf-news-content {
  flex: 1;
  padding: 20px;
}

.news-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  padding: 20px;
}

@media (max-width: 768px) {
  .wtf-news-container {
    padding: 10px;
  }
  
  .news-grid {
    grid-template-columns: 1fr;
  }
}
