.crew-page-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 8px;
}
.crew-page-container01 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.crew-page-container02 {
  flex: 0 0 auto;
  width: 100%;
  height: 301px;
  display: flex;
  position: relative;
  align-self: flex-start;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.crew-page-container03 {
  width: 408px;
  border: none; /* Border hidden */
  height: 369px;
  display: flex;
  align-items: flex-start;
}
.crew-page-container04 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.crew-page-container05 {
  flex: 0 0 auto;
  width: 100%;
  height: 301px;
  display: flex;
  position: relative;
  align-self: flex-start;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.crew-page-container06 {
  width: 408px;
  border: none; /* Border hidden */
  height: 369px;
  display: flex;
  align-items: flex-start;
}
.crew-page-container07 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.crew-page-container08 {
  flex: 0 0 auto;
  width: 100%;
  height: 301px;
  display: flex;
  position: relative;
  align-self: flex-start;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.crew-page-container09 {
  width: 408px;
  border: none; /* Border hidden */
  height: 369px;
  display: flex;
  align-items: flex-start;
}
.crew-page-container10 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.crew-page-container11 {
  flex: 0 0 auto;
  width: 100%;
  height: 301px;
  display: flex;
  position: relative;
  align-self: flex-start;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.crew-page-container12 {
  width: 408px;
  border: none; /* Border hidden */
  height: 369px;
  display: flex;
  align-items: flex-start;
}
@media(max-width: 991px) {
  .crew-page-container02 {
    height: 489px;
  }
  .crew-page-container05 {
    height: 489px;
  }
  .crew-page-container08 {
    height: 489px;
  }
  .crew-page-container11 {
    height: 489px;
  }
}
.crew-cards-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px; /* You can adjust the gap to your preference */
}

.top-margin {
  margin-top: 20px; /* Adjust the value as needed */
}

.crew-card {
  margin: 8px; /* Margin around each card */
  /* Add any other styling you want for the individual crew cards */
}