:root {
  --dl-color-gray-500: #595959;
  --dl-color-gray-700: #999999;
  --dl-color-gray-900: #ffffff;
  --dl-size-size-large: 144px;
  --dl-size-size-small: 48px;
  --dl-color-danger-300: #A22020;
  --dl-color-danger-500: #BF2626;
  --dl-color-danger-700: #E14747;
  --dl-color-gray-black: #000000;
  --dl-color-gray-white: #f3f3f3ff;
  --dl-size-size-medium: 96px;
  --dl-size-size-xlarge: 192px;
  --dl-size-size-xsmall: 16px;
  --dl-space-space-unit: 16px;
  --dl-color-primary-100: #003EB3;
  --dl-color-primary-300: #0074F0;
  --dl-color-primary-500: #14A9FF;
  --dl-color-primary-700: #85DCFF;
  --dl-color-success-300: #199033;
  --dl-color-success-500: #32A94C;
  --dl-color-success-700: #4CC366;
  --dl-size-size-xxlarge: 288px;
  --dl-size-size-maxwidth: 1400px;
  --dl-color-theme-accent1: #F3E5D8;
  --dl-color-theme-accent2: #F7D7BA;
  --dl-radius-radius-round: 50%;
  --dl-color-theme-primary1: #000000ff;
  --dl-color-theme-primary2: #121417;
  --dl-space-space-halfunit: 8px;
  --dl-space-space-sixunits: 96px;
  --dl-space-space-twounits: 32px;
  --dl-radius-radius-radius2: 2px;
  --dl-radius-radius-radius4: 4px;
  --dl-radius-radius-radius8: 8px;
  --dl-space-space-fiveunits: 80px;
  --dl-space-space-fourunits: 64px;
  --dl-color-theme-secondary1: #FFEBEA;
  --dl-color-theme-secondary2: #F7C5C3;
  --dl-space-space-threeunits: 48px;
  --dl-color-theme-neutral-dark: #3E4650;
  --dl-radius-radius-cardradius: 8px;
  --dl-color-theme-neutral-light: #FDFAF7;
  --dl-radius-radius-imageradius: 8px;
  --dl-radius-radius-inputradius: 24px;
  --dl-radius-radius-buttonradius: 24px;
  --dl-space-space-oneandhalfunits: 24px;
}
.button {
  color: var(--dl-color-gray-black);
  display: inline-block;
  padding: 0.5rem 1rem;
  border-color: var(--dl-color-gray-black);
  border-width: 1px;
  border-radius: 4px;
  background-color: var(--dl-color-gray-white);
}
.input {
  color: var(--dl-color-gray-black);
  cursor: auto;
  padding: 0.5rem 1rem;
  border-color: var(--dl-color-gray-black);
  border-width: 1px;
  border-radius: 4px;
  background-color: var(--dl-color-gray-white);
}
.textarea {
  color: var(--dl-color-gray-black);
  cursor: auto;
  padding: 0.5rem;
  border-color: var(--dl-color-gray-black);
  border-width: 1px;
  border-radius: 4px;
  background-color: var(--dl-color-gray-white);
}
.list {
  width: 100%;
  margin: 1em 0px 1em 0px;
  display: block;
  padding: 0px 0px 0px 1.5rem;
  list-style-type: none;
  list-style-position: outside;
}
.list-item {
  display: list-item;
}
.teleport-show {
  display: flex !important;
  transform: translateX(0%) !important;
}
.thq-input {
  color: var(--dl-color-theme-neutral-dark);
  cursor: auto;
  padding: 0.5rem 1rem;
  align-self: stretch;
  text-align: center;
  border-color: var(--dl-color-theme-neutral-dark);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-inputradius);
  background-color: var(--dl-color-theme-neutral-light);
}
.thq-button-filled {
  color: var(--dl-color-theme-secondary1);
  cursor: pointer;
  transition: 0.3s;
  font-family: Noto Sans;
  font-weight: bold;
  padding-top: var(--dl-space-space-halfunit);
  white-space: nowrap;
  border-color: var(--dl-color-theme-primary1);
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: var(--dl-radius-radius-buttonradius);
  padding-right: var(--dl-space-space-oneandhalfunits);
  padding-bottom: var(--dl-space-space-halfunit);
  background-color: var(--dl-color-theme-primary1);
}
.thq-button-filled:hover {
  color: var(--dl-color-theme-secondary2);
  border-color: var(--dl-color-theme-primary2);
  background-color: var(--dl-color-theme-primary2);
}
.thq-button-outline {
  fill: var(--dl-color-theme-primary1);
  color: var(--dl-color-theme-primary1);
  border: 1px solid;
  cursor: pointer;
  transition: 0.3s;
  font-weight: bold;
  padding-top: var(--dl-space-space-halfunit);
  white-space: nowrap;
  border-color: var(--dl-color-theme-primary1);
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: var(--dl-radius-radius-buttonradius);
  padding-right: var(--dl-space-space-oneandhalfunits);
  padding-bottom: var(--dl-space-space-halfunit);
}
.thq-button-outline:hover {
  fill: var(--dl-color-theme-secondary2);
  color: var(--dl-color-theme-secondary2);
  background-color: var(--dl-color-theme-primary2);
}
.thq-button-flat {
  gap: var(--dl-space-space-halfunit);
  fill: var(--dl-color-theme-primary1);
  color: var(--dl-color-theme-primary1);
  cursor: pointer;
  display: flex;
  transition: 0.3s;
  align-items: center;
  font-family: Noto Sans;
  font-weight: bold;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: var(--dl-radius-radius-buttonradius);
  padding-right: var(--dl-space-space-oneandhalfunits);
  padding-bottom: var(--dl-space-space-halfunit);
}
.thq-button-flat:hover {
  fill: var(--dl-color-theme-secondary1);
  color: var(--dl-color-theme-secondary1);
  border-color: var(--dl-color-theme-primary2);
  background-color: var(--dl-color-theme-primary2);
}
.thq-heading-1 {
  font-size: 48px;
  font-family: Laila;
  font-weight: 700;
  line-height: 1.5;
}
.thq-heading-2 {
  font-size: 35px;
  font-family: Laila;
  font-weight: 600;
  line-height: 1.5;
}
.thq-heading-3 {
  font-size: 26px;
  font-family: Laila;
  font-weight: 600;
  line-height: 1.5;
}
.thq-body-large {
  font-size: 18px;
  font-family: Nunito;
  line-height: 1.5;
}
.thq-body-small {
  font-size: 16px;
  font-family: Nunito;
  line-height: 1.5;
}
.thq-team-image-round {
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 50%;
}
.thq-section-padding {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-fiveunits);
  position: relative;
  align-items: center;
  flex-direction: column;
}
.thq-section-max-width {
  width: 100%;
  max-width: var(--dl-size-size-maxwidth);
}
.thq-img-ratio-1-1 {
  width: 100%;
  object-fit: cover;
  aspect-ratio: 1/1;
  border-radius: var(--dl-radius-radius-imageradius);
}
.thq-img-ratio-16-9 {
  width: 100%;
  object-fit: cover;
  aspect-ratio: 16/9;
  border-radius: var(--dl-radius-radius-imageradius);
}
.thq-img-ratio-4-3 {
  width: 100%;
  object-fit: cover;
  aspect-ratio: 4/3;
  border-radius: var(--dl-radius-radius-imageradius);
}
.thq-img-ratio-4-6 {
  width: 100%;
  object-fit: cover;
  aspect-ratio: 4/6;
  border-radius: var(--dl-radius-radius-imageradius);
}
.thq-img-round {
  width: 100%;
  border-radius: var(--dl-radius-radius-round);
}
.thq-flex-column {
  gap: var(--dl-space-space-twounits);
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  flex-direction: column;
}
.thq-flex-row {
  gap: var(--dl-space-space-twounits);
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
}
.thq-grid-6 {
  display: grid;
  grid-gap: var(--dl-space-space-twounits);
  place-items: center;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
}
.thq-grid-5 {
  display: grid;
  grid-gap: var(--dl-space-space-twounits);
  place-items: center;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}
.thq-card {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  padding: var(--dl-space-space-twounits);
  align-items: stretch;
  border-radius: var(--dl-radius-radius-cardradius);
  flex-direction: column;
}
.thq-box-shadow {
  box-shadow: 5px 5px 10px 0px #d4d4d4;
}
.thq-grid-3 {
  display: grid;
  grid-gap: var(--dl-space-space-twounits);
  place-items: center;
  grid-template-columns: 1fr 1fr 1fr;
}
.thq-grid-4 {
  display: grid;
  grid-gap: var(--dl-space-space-twounits);
  place-items: center;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}
.thq-grid-2 {
  width: 100%;
  display: grid;
  grid-gap: var(--dl-space-space-twounits);
  place-items: center;
  grid-template-columns: 1fr 1fr;
}
.thq-checkbox {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.thq-select {
  cursor: pointer;
  appearance: none;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-inputradius);
  padding-right: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-halfunit);
  background-color: var(--dl-color-theme-neutral-light);
  background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg width%3D%2220%22 height%3D%2220%22 xmlns%3D%22http%3A//www.w3.org/2000/svg%22 viewBox%3D%220 0 20 20%22 fill%3D%22%23000%22%3E%3Cpath d%3D%22M4.293 7.293a1 1 0 011.414 0L10 11.586l4.293-4.293a1 1 0 111.414 1.414l-5 5a1 1 0 01-1.414 0l-5-5a1 1 0 010-1.414z%22/%3E%3C/svg%3E');
  background-repeat: no-repeat;
  background-position: right 8px center;
}
.thq-divider-horizontal {
  width: 100%;
  height: 1px;
  background-color: var(--dl-color-theme-neutral-dark);
}
.thq-icon-small {
  width: 24px;
  height: 24px;
}
.thq-button-icon {
  fill: var(--dl-color-theme-secondary1);
  padding: var(--dl-space-space-halfunit);
  transition: 0.3s;
  font-family: Noto Sans;
  border-radius: var(--dl-radius-radius-buttonradius);
}
.thq-button-icon:hover {
  fill: var(--dl-color-theme-secondary2);
}
.thq-icon-medium {
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
}
.thq-icon-x-small {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.thq-link {
  cursor: pointer;
  transition: 0.3s;
  font-weight: 600;
}
.thq-link:hover {
  opacity: 0.8;
}
.thq-input::placeholder {
  text-align: center;
  vertical-align: middle;
}
.thq-grid-auto-300 {
  display: grid;
  grid-gap: var(--dl-space-space-oneandhalfunits);
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
}
.thq-animated-group-vertical-reverse {
  gap: var(--dl-space-space-unit);
  width: 100%;
  display: flex;
  animation: scroll-y 20s linear infinite;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-around;
  animation-direction: reverse;
}
.thq-animated-group-horizontal-reverse {
  gap: var(--dl-space-space-unit);
  display: flex;
  animation: scroll-x 20s linear infinite;
  min-width: 100%;
  align-items: center;
  flex-shrink: 0;
  justify-content: space-around;
  animation-direction: reverse;
}
.thq-animated-group-vertical {
  gap: var(--dl-space-space-unit);
  width: 100%;
  display: flex;
  animation: scroll-y 20s linear infinite;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-around;
}
.thq-animated-group-horizontal {
  gap: var(--dl-space-space-unit);
  display: flex;
  animation: scroll-x 20s linear infinite;
  min-width: 100%;
  align-items: center;
  flex-shrink: 0;
  justify-content: space-around;
}
.thq-animated-group-container-vertical {
  gap: var(--dl-space-space-unit);
  display: flex;
  overflow: hidden;
  flex-direction: column;
}
.thq-animated-group-container-horizontal {
  gap: var(--dl-space-space-unit);
  display: flex;
  overflow: hidden;
}
.thq-mask-image-vertical {
  mask-image: linear-gradient(to bottom, transparent, black 1%, black 99%, transparent);
}
.thq-mask-image-horizontal {
  mask-image: linear-gradient(to right, transparent, black 1%, black 99%, transparent);
}
.thq-img-scale {
  transition: 0.3s;
}
.thq-img-scale:hover {
  scale: 1.05;
}
.thq-animated-card-bg-1 {
  width: 100%;
  height: 100%;
  transition: transform 0.3s;
  border-radius: var(--dl-radius-radius-cardradius);
  background-color: var(--dl-color-theme-accent1);
}
.thq-animated-card-bg-2 {
  transition: transform 0.3s;
  border-radius: var(--dl-radius-radius-cardradius);
  background-color: var(--dl-color-theme-accent2);
}
.thq-button-animated {
  outline: none;
  z-index: 1;
  overflow: hidden;
  position: relative;
  border-width: 2px;
}
.thq-animated-group-container-vertical:hover div {
  animation-play-state: paused;
}
.thq-animated-group-container-horizontal:hover div {
  animation-play-state: paused;
}
.thq-animated-card-bg-2:has([data-animated="true"]:hover) {
  transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(3deg) skew(0deg, 0deg);
}
.thq-animated-card-bg-1:has([data-animated="true"]:hover) {
  transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(-6deg) skew(0deg, 0deg);
}
.thq-button-animated:before {
  top: 0;
  left: -20%;
  color: var(--dl-color-theme-neutral-light);
  width: 200%;
  height: 101%;
  content: "";
  z-index: 1;
  position: absolute;
  transform: scaleX(0);
  transition: transform 0.5s;
  border-radius: var(--dl-radius-radius-buttonradius);
  background-color: var(--dl-color-theme-neutral-dark);
  transform-origin: 0 0;
  transition-timing-function: cubic-bezier(0.5, 1.6, 0.4, 0.7);
}
.thq-button-animated:hover::before {
  color: var(--dl-color-theme-neutral-light);
  z-index: -1;
  transform: scaleX(1);
}
.Content {
  font-size: 16px;
  font-family: Inter;
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.Heading {
  font-size: 32px;
  font-family: Inter;
  font-weight: 700;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
@media(max-width: 991px) {
  .thq-flex-row {
    flex-direction: column;
  }
  .thq-grid-4 {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
@media(max-width: 767px) {
  .thq-section-padding {
    padding: var(--dl-space-space-threeunits);
  }
  .thq-flex-column {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .thq-flex-row {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .thq-grid-6 {
    grid-gap: var(--dl-space-space-oneandhalfunits);
    grid-template-columns: 1fr 1fr 1fr;
  }
  .thq-grid-5 {
    grid-gap: var(--dl-space-space-oneandhalfunits);
    grid-template-columns: 1fr 1fr 1fr;
  }
  .thq-card {
    padding: var(--dl-space-space-oneandhalfunits);
  }
  .thq-grid-3 {
    grid-gap: var(--dl-space-space-oneandhalfunits);
    grid-template-columns: 1fr 1fr;
  }
  .thq-grid-4 {
    grid-gap: var(--dl-space-space-oneandhalfunits);
    flex-direction: row;
    grid-template-columns: 1fr 1fr;
  }
  .thq-grid-2 {
    grid-gap: var(--dl-space-space-oneandhalfunits);
    grid-template-columns: 1fr;
  }
  .thq-img-scale {
    width: 100%;
  }
}
@media(max-width: 479px) {
  .thq-section-padding {
    padding: var(--dl-space-space-oneandhalfunits);
  }
  .thq-flex-column {
    gap: var(--dl-space-space-unit);
  }
  .thq-flex-row {
    gap: var(--dl-space-space-unit);
  }
  .thq-grid-6 {
    grid-gap: var(--dl-space-space-unit);
    grid-template-columns: 1fr 1fr;
  }
  .thq-grid-5 {
    grid-gap: var(--dl-space-space-unit);
    grid-template-columns: 1fr 1fr;
  }
  .thq-grid-3 {
    grid-gap: var(--dl-space-space-unit);
    align-items: center;
    grid-template-columns: 1fr;
  }
  .thq-grid-4 {
    grid-gap: var(--dl-space-space-unit);
    align-items: center;
    flex-direction: column;
    grid-template-columns: 1fr;
  }
  .thq-grid-2 {
    grid-gap: var(--dl-space-space-unit);
  }
  .thq-grid-auto-300 {
    grid-template-columns: 1fr;
  }
}
