.about-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  text-align: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.about-text1 {
  color: rgb(255, 255, 255);
  margin: var(--dl-space-space-threeunits);
  display: inline-block;
  padding: var(--dl-space-space-unit);
  text-align: center;
  background-color: rgba(8, 8, 8, 0.78);
}
.about-text2 {
  color: rgb(255, 255, 255);
  display: inline-block;
  padding: var(--dl-space-space-unit);
  font-size: 26px;
  background-color: rgb(0, 0, 0);
}
@media(max-width: 991px) {
  .about-text1 {
    color: #ffffff;
    padding: var(--dl-space-space-twounits);
    text-align: center;
    background-color: rgba(2, 2, 2, 0.86);
  }
}
@media(max-width: 420px) {
  .about-text1 {
    color: #ffffff;
    margin: var(--dl-space-space-unit);
    padding: var(--dl-space-space-unit);
    text-align: center;
    background-color: rgba(8, 8, 8, 0.73);
  }
  .about-text2 {
    font-size: 16px;
    text-align: center;
  }
}
