.era-card-container {
  width: 200px;
  height: 200px;
  margin: var(--dl-space-space-halfunit);
  display: flex;
  position: relative;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  flex-direction: column;
  background-size: cover;
  justify-content: center;
  background-image: url('https://play.teleporthq.io/static/svg/default-img.svg');
}
.era-card-text2 {
  color: rgb(255, 255, 255);
  margin: var(--dl-space-space-halfunit);
  display: inline-block;
  padding: var(--dl-space-space-halfunit);
  font-size: 12px;
  background-color: rgba(32, 32, 32, 0.81);
}
@media(max-width: 479px) {
  .MobileBGCover{
    background-color: rgba(128, 128, 128, 0. 91);
    width: 100%;
    height: 100%;
  }
  .era-card-container {
    width: 100%;
    height: 100px;
    background-size:  cover ;
    background-repeat:no-repeat;
    background-position: center;
    Border-radius: var(--dl-radius-radius-radius4);
    
    background-color: rgba(128, 128, 128, 0.5);
  }
}
