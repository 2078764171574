.news-details-page-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.news-details-page-container1 {
  width: 100%;
  height: 326px;
  display: flex;
  padding: var(--dl-space-space-unit);
  margin-top: var(--dl-space-space-twounits);
  margin-left: 0px;
  margin-right: 0px;
  margin-bottom: 0px;
  background-size: cover;
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.65) 0.00%,rgba(0, 0, 0, 0.65) 0.00%),url("/external/dth_goom_era-1500w.png");
  background-repeat: no-repeat;
  background-position: top;
}
.news-details-page-container2 {
  flex: 0 0 auto;
  width: 100%;
  height: 353px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.news-details-page-feature-card {
  width: 100%;
  height: 353px;
  display: flex;
  padding: var(--dl-space-space-unit);
  position: relative;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.news-details-page-text {
  color: var(--dl-color-gray-white);
  width: auto;
  height: auto;
  padding: var(--dl-space-space-unit);
  font-size: 28px;
  align-self: flex-start;
  font-style: normal;
  margin-top: var(--dl-space-space-halfunit);
  text-align: left;
  font-weight: 800;
  margin-left: var(--dl-space-space-halfunit);
  margin-right: var(--dl-space-space-halfunit);
  margin-bottom: var(--dl-space-space-halfunit);
  text-transform: capitalize;
  background-color: rgb(6, 6, 6);
}
.news-details-page-text1 {
  color: rgb(0, 0, 0);
  height: auto;
  font-size: 12px;
  align-self: flex-start;
  font-style: normal;
  margin-top: var(--dl-space-space-halfunit);
  text-align: center;
  font-weight: 500;
  margin-left: var(--dl-space-space-halfunit);
  margin-right: var(--dl-space-space-halfunit);
  margin-bottom: var(--dl-space-space-halfunit);
  line-height: 1.5;
}
.news-details-page-container3 {
  flex: 0 0 auto;
  width: 100%;
  /* border: 2px dashed rgba(120, 120, 120, 0.4); */
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media(max-width: 991px) {
  .news-details-page-feature-card {
    width: auto;
    height: auto;
  }
  .news-details-page-text {
    width: auto;
    height: auto;
  }
}
@media(max-width: 420px) {
  .news-details-page-container1 {
    height: 196px;
    margin-top: var(--dl-space-space-unit);
    margin-left: 0px;
    margin-right: 0px;
  }
  .news-details-page-container2 {
    height: auto;
  }
  .news-details-page-feature-card {
    width: 100%;
  }
  .news-details-page-text {
    width: auto;
    height: auto;
    font-size: 18px;
  }
  .news-details-page-text1 {
    width: auto;
    height: auto;
    font-size: 12px;
    line-height: 1.5;
  }
}
