.search-container {
  width: 100vw;
  display: flex;
  overflow: auto;
  flex-wrap: wrap;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.search-blog {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}
.search-textinput {
  width: 100%;
  height: var(--dl-size-size-medium);
  font-size: 24px;
  border-width: 2px;
  background-color: #d6d5d5;
}
.search-container1 {
  width: 100%;
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: column;
  justify-content: space-between;
}
.search-container2 {
  width: 100%;
  display: flex;
  align-items: center;
  border-color: rgba(120, 120, 120, 0.4);
  border-style: dashed;
  border-width: 0px;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: column;
  justify-content: space-between;
}
#MobileNote {
  background-color: red;
  color: white;
}


