.dthpw-menu-container {
   width: 100%;
    height: 91px;
    display: flex;
    position: sticky;
    align-items: stretch;
    flex-direction: row;
    justify-content: flex-start; /* aligned to right side */
  Z-index: 500;
}

  
.dthpw-menu-navbar-interactive {
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  flex-wrap: wrap;
  align-self: stretch;
  align-items: center;
  padding: var(--dl-space-space-twounits);
  padding-left: 15px;
  padding-right: var(--dl-space-space-halfunit);
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: center;
  background-color: #000000;
  
}
.dthpw-menu-desktop-menu {
  display: flex;
}
.dthpw-menu-nav {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.dthpw-menu-navlink {
  color: #ffffff;
  font-size: 18px;
  text-decoration: none;
}
.dthpw-menu-navlink01 {
  color: rgb(255, 255, 255);
  font-size: 18px;
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
}
.dthpw-menu-navlink02 {
  color: rgb(255, 255, 255);
  font-size: 18px;
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
}
.dthpw-menu-navlink03 {
  color: rgb(255, 255, 255);
  font-size: 18px;
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
}
.dthpw-menu-navlink04 {
  color: rgb(255, 255, 255);
  font-size: 18px;
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
}
.dthpw-menu-navlink05 {
  color: rgb(255, 255, 255);
  font-size: 18px;
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
}
.dthpw-menu-btn-group {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.dthpw-menu-burger-menu {
  display: none;
  background: white;
  align-items: center;
  justify-content: center;
}
.dthpw-menu-icon {
  width: var(--dl-size-size-medium);
  height: var(--dl-size-size-medium);
  background-color: var(--dl-color-gray-white);
}
.dthpw-menu-mobile-menu {
  top: 0px;
  left: 0px;
  width: 1200px;
  height: 1020px;
  display: flex;
  padding: 32px;
  z-index: 200;
  position: fixed;
  transform: translateX(-100%);
  transition: 0.5s;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
  
}
.dthpw-menu-nav1 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.dthpw-menu-container1 {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.dthpw-menu-menu-close {
  display: flex;
  align-items: center;
  justify-content: center;
}
.dthpw-menu-icon02 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.dthpw-menu-nav2 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.dthpw-menu-navlink06 {
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.dthpw-menu-navlink07 {
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.dthpw-menu-navlink08 {
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.dthpw-menu-navlink09 {
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.dthpw-menu-navlink10 {
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.dthpw-menu-navlink11 {
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.dthpw-menu-container2 {
  flex: 0 0 auto;
  width: auto;
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: flex;
  margin-top: var(--dl-space-space-unit);
  align-items: center;
  flex-direction: row;
}
.dthpw-menu-icon-group {
  display: flex;
}
.dthpw-menu-icon04 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.dthpw-menu-icon06 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.dthpw-menu-icon08 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.dthpw-menu-textinput {
  width: 513px;
  height: 52px;
  align-self: center;
  border-width: 0px;
}
.dthpw-menu-root-class-name {
  align-self: flex-start;
}

.dthpw-menu-root-class-name2 {
  height: 100%;
  align-self: stretch;
}


.dthpw-menu-root-class-name5 {
  align-self: flex-start;
}






@media(max-width: 991px) {
  .dthpw-menu-navbar-interactive {
    width: 627px;
    height: 99px;
  }
  .dthpw-menu-textinput {
    width: 336px;
    height: 100%;
  }
}
@media(max-width: 479px) {
  .dthpw-menu-navbar-interactive {
    height: 92px;
    
  }
  .dthpw-menu-desktop-menu {
    display: none;
  }
  .dthpw-menu-burger-menu {
    display: flex;
    position: relative;
  }
  .dthpw-menu-icon {
    
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(var(--dl-size-size-medium) * 0.45);
    height: calc(var(--dl-size-size-medium) * 0.45);
    background-color: var(--dl-color-gray-white);

  
  }

DTHSearchBox{
  width: 45%
}


  .dthpw-menu-container{
    width: 100%;
  }
  
  .dthpw-menu-icon02 {
    width: var(--dl-size-size-small);
    height: var(--dl-size-size-small);
  }
  .dthpw-menu-navlink10 {
    text-decoration: none;
  }
  .dthpw-menu-navlink11 {
    text-decoration: none;
  }
}
