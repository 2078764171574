.spank-bank-details-page-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.spank-bank-details-page-container1 {
  flex: 0 0 auto;
  width: 100%;
  height: 353px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.spank-bank-details-page-feature-card {
  width: 100%;
  height: 353px;
  display: flex;
  padding: var(--dl-space-space-unit);
  position: relative;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.spank-bank-details-page-text {
  color: var(--dl-color-gray-white);
  width: auto;
  height: auto;
  padding: var(--dl-space-space-unit);
  font-size: 28px;
  align-self: flex-start;
  font-style: normal;
  margin-top: var(--dl-space-space-halfunit);
  text-align: left;
  font-weight: 800;
  margin-left: var(--dl-space-space-halfunit);
  margin-right: var(--dl-space-space-halfunit);
  margin-bottom: var(--dl-space-space-halfunit);
  text-transform: capitalize;
  background-color: rgb(6, 6, 6);
}
.spank-bank-details-page-text1 {
  color: rgb(0, 0, 0);
  height: auto;
  font-size: 16px;
  align-self: flex-start;
  font-style: normal;
  margin-top: var(--dl-space-space-halfunit);
  text-align: center;
  font-weight: 500;
  margin-left: var(--dl-space-space-halfunit);
  margin-right: var(--dl-space-space-halfunit);
  margin-bottom: var(--dl-space-space-halfunit);
}
@media(max-width: 991px) {
  .spank-bank-details-page-feature-card {
    width: auto;
    height: auto;
  }
  .spank-bank-details-page-text {
    width: auto;
    height: auto;
  }
}
@media(max-width: 420px) {
  .spank-bank-details-page-container1 {
    height: auto;
  }
  .spank-bank-details-page-feature-card {
    width: 100%;
  }
  .spank-bank-details-page-text {
    width: auto;
    height: auto;
    font-size: 18px;
  }
  .spank-bank-details-page-text1 {
    width: auto;
    height: auto;
    font-size: 12px;
  }
}
