.dthepicard-container {
  width: 247px;
  height: 237px;
  display: flex;
  position: relative;
  align-items: center;
  border-color: #ffffff;
  border-style: dashed;
  border-width: 0px;
  flex-direction: column;
  background-size: cover;
  justify-content: center;
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.6) 100.00%,rgba(0, 0, 0, 0.6) 100.00%,rgba(0, 0, 0, 0.6) 100.00%),url("https://dthepi.nyc3.cdn.digitaloceanspaces.com/Assets/hamYT.png");
  background-repeat: top left,center;
}
.dthepicard-container1 {
  flex: 0 0 auto;
  width: 100%;
  height: 100px;
  display: flex;
  position: relative;
  align-items: flex-end;
  flex-direction: column;
  justify-content: center;
  
}
.dthepicard-text {
  font-size: 18px;
  padding-left: var(--dl-space-space-halfunit);
  margin-bottom: var(--dl-space-space-halfunit);
  padding-right: var(--dl-space-space-halfunit);
  background-color: #D9D9D9;
  font-weight: bold;
  width: auto;
  
}
.dthepicard-text1 {
  color: #ffffff;
  font-size: 14px;
  padding-left: var(--dl-space-space-halfunit);
  padding-right: var(--dl-space-space-halfunit);
  background-color: #000000;
  width: auto;
}
.dthepicard-container2 {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.dthepicard-icon {
  fill: #D9D9D9;
  right: -14px;
  width: 47px;
  bottom: -60px;
  height: 46px;
  position: absolute;
  border-color: var(--dl-color-gray-black);
  border-width: 5px;
}
.dthepicard-root-class-name {
  width: var(--dl-size-size-xxlarge);
  height: var(--dl-size-size-xxlarge);
}
.dthepicard-root-class-name1 {
  width: 300px;
  height: 300px;
  margin: var(--dl-space-space-unit);
}
@media(max-width: 991px) {
  .dthepicard-container {
    width: 261px;
    height: 235px;
    border-width: 0px;
    background-image: url("/gahamradio-1500w.png");
    background-repeat: top left,center;
    background-position: center,top left;
  }
  .dthepicard-text {
    font-size: 16px;
    background-color: #D9D9D9;
  }
  .dthepicard-icon {
    top: 10px;
    right: -20px;
  }
  .dthepicard-root-class-name {
    margin: var(--dl-space-space-halfunit);
  }
}
@media(max-width: 420px) {
  .dthepicard-container {
    width: 100%;
    height: var(--dl-size-size-xxlarge);
    border-width: 0px;
  }
  .dthepicard-icon {
    display: none;
  }
  .dthepicard-root-class-name {
    flex: 1;
    width: 100%;
    margin: var(--dl-space-space-halfunit);
  }

  .dthepicard-text {
    font-size: 16px;
    background-color: #D9D9D9;
    white-space:wrap;
  }
}
