.dth-global-player-web-container {
  width: 100%;
  height: 200px;
  display: flex;
  position: relative;
  align-items: center;
  border-color: var(--dl-color-theme-neutral-dark);
  border-width: 0px;
  flex-direction: row;
  justify-content: flex-start;
}
.dth-global-player-web-container1 {
  flex: 0 0 auto;
  width: 15%;
  height: 100%;
  display: flex;
  align-self: flex-start;
  align-items: center;
  border-color: rgba(120, 120, 120, 0.4);
  border-style: hidden;
  border-width: 0px;
  justify-content: center;
  background-color: #000000;
}
.dth-global-player-web-icon {
  fill: #ffffff;
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
}
.dth-global-player-web-container2 {
  flex: 0 0 auto;
  width: 15%;
  height: 100%;
  display: flex;
  align-items: center;
  border-color: rgba(120, 120, 120, 0.4);
  border-style: hidden;
  border-width: 0px;
  justify-content: center;
  background-color: #ffffff;
}
.dth-global-player-web-image {
  width: 144px;
  height: 156px;
  object-fit: cover;
}
.dth-global-player-web-container3 {
  flex: 0 0 auto;
  width: 70%;
  height: 100%;
  display: flex;
  align-items: center;
  background-size: cover;
  justify-content: center;
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0.7) 100%), url("https://dthepi.nyc3.cdn.digitaloceanspaces.com/Assets/DTHCrew/HAM%20Radio%20Show-H247.png");
  background-repeat: no-repeat;
  background-position: center;
}
.dth-global-player-web-container4 {
  flex: 1;
  width: 239px;
  height: 100%;
  display: flex;
  position: relative;
  align-items: center;
  border-color: rgba(120, 120, 120, 0.4);
  border-style: hidden;
  border-width: 0px;
  justify-content: center;
}
.dth-global-player-web-text {
  top: 103px;
  left: 44px;
  color: #ffffff;
  padding: var(--dl-space-space-halfunit);
  position: absolute;
  font-size: 14px;
  background-color: rgba(80, 80, 80, 0.63);
}
.dth-global-player-web-text1 {
  top: 53px;
  left: 42px;
  color: #000000;
  margin: auto;
  padding: var(--dl-space-space-halfunit);
  position: absolute;
  font-size: 14px;
  align-self: flex-start;
  text-align: left;
  background-color: #fff8f8;
}
.dth-global-player-web-image1 {
  flex: 0;
  width: 100%;
  height: 100%;
  align-self: flex-end;
  object-fit: cover;
}
.dth-global-player-web-container5 {
  top: 70px;
  flex: 0 0 auto;
  right: 68px;
  width: 65px;
  height: 65px;
  display: flex;
  position: absolute;
  align-items: center;
  border-color: rgba(120, 120, 120, 0.4);
  border-style: hidden;
  border-width: 2px;
  justify-content: center;
  background-color: #ffffff;
}
.dth-global-player-web-icon2 {
  width: 24px;
  height: 24px;
}
@media(max-width: 991px) {
  .dth-global-player-web-image {
    width: 120px;
    height: 122px;
  }
}

.modal-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw; /* Full-width of the viewport */
  height: 100vh; /* Full-height of the viewport */
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  max-width: 90%; /* Optional: restrict max-width */
  max-height: 90%; /* Optional: restrict max-height */
  overflow: auto; /* Handle overflow content gracefully */
}