.episode-details-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.episode-details-container1 {
  width: 1188px;
  height: 52px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  border-color: rgba(120, 120, 120, 0.4);
  border-style: dashed;
  border-width: 0px;
}

.episode-details-button{
margin: 8px;
padding: 8px;
color: white; /* Use 'color' instead of 'text-color' for font color */
background: black;
border-radius: 4px;
}

.episode-details-container2 {
  width: 100%;
  height: 335px;
  margin: var(--dl-space-space-unit);
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  border-color: rgba(120, 120, 120, 0.4);
  border-style: dashed;
  border-width: 0px;
  background-size: cover;
  justify-content: flex-start;
  background-image: url("https://dthepi.nyc3.cdn.digitaloceanspaces.com/Assets/DTHCrew/HAM%20Radio%20GOOM%20STUDIO.png");
  background-repeat: repeat,no-repeat;
  background-position: center,top;
  height: auto; /* Allow it to expand */
  min-height: 335px;
}
.episode-details-icon {
  fill: #D9D9D9;
  width: var(--dl-size-size-medium);
  height: var(--dl-size-size-medium);
  margin: var(--dl-space-space-unit);
}
.episode-details-container3 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.episode-details-text {
  margin: var(--dl-space-space-halfunit);
  padding: var(--dl-space-space-halfunit);
  font-size: 24px;
  text-align: center;
  background-color: rgb(217, 217, 217);
}
.episode-details-text1 {
  padding: var(--dl-space-space-halfunit);
  font-size: 18px;
  align-self: center;
  text-align: center;
  background-color: rgb(111, 111, 111);
}
.episode-details-container4 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.episode-details-text4 {
  color: rgb(255, 255, 255);
  margin: var(--dl-space-space-unit);
  padding: var(--dl-space-space-unit);
  font-size: 16px;
  font-style: normal;
  text-align: center;
  font-weight: 300;
  text-transform: capitalize;
  background-color: rgb(0, 0, 0);
  line-height: 1.5;
}
.episode-details-container5 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  align-self: center;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
}
.episode-details-icon2 {
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-medium);
  margin: var(--dl-space-space-halfunit);
}
.episode-details-icon4 {
  width: var(--dl-size-size-small);
  height: auto;
  margin: var(--dl-space-space-halfunit);
}
@media(max-width: 991px) {
  .episode-details-container2 {
    width: 100%;
  }
}
@media(max-width: 420px) {
  .episode-details-container1{
    max-width: 100%;
  }
  .episode-details-container2 {
    height: auto;
    
    background-position: center,top;
  }
  .episode-details-text {
    margin: var(--dl-space-space-halfunit);
    padding: var(--dl-space-space-halfunit);
    font-size: 14px;
    text-align: center;
    background-color: #D9D9D9;
    flex-wrap: wrap; /* Allow text to wrap */
  }
  .episode-details-text1 {
    font-size: 10px;
    flex-wrap: wrap; /* Allow text to wrap */
    margin: var(--dl-space-space-halfunit); 
      }
  .episode-details-text4 {
    color: #ffffff;
    padding: var(--dl-space-space-halfunit);
    font-size: 12px;
    text-align: center;
    background-color: #000000;
    line-height: 1.5;
  }
  .episode-details-icon2 {
    width: var(--dl-size-size-small);
    height: auto;
  }
}
