.home-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;

}
.home-container1 {
  flex: 0 0 auto;
  width: 100%;
  height: var(--dl-size-size-small);
  display: flex;
  align-items: flex-start;
  border-color: rgba(120, 120, 120, 0.4);
  border-style: dashed;
  border-width: 0px;
}
.home-text {
  color: #ffffff;
  width: 100%;
  height: 100%;
  margin: var(--dl-space-space-halfunit);
  padding: var(--dl-space-space-unit);
  font-size: 24px;
  text-align: center;
  background-color: #000000;
}
.home-container2 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  z-index: 800;
  background-color: #fffff
}
.home-container3 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  margin: 0px;
  display: flex;
  flex-wrap: wrap;
  align-self: flex-start;
  align-items: center;
  justify-content: flex-start;
}
.home-text3 {
  color: #ffffff;
  width: 100%;
  height: 100%;
  margin: var(--dl-space-space-halfunit);
  padding: var(--dl-space-space-unit);
  font-size: 24px;
  text-align: center;
  background-color: #000000;
}
.home-container4 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-self: flex-start;
  align-items: center;
  justify-content: flex-start;
  
}
@media(max-width: 991px) {
  .home-container1 {
    height: var(--dl-size-size-small);
  }
}
@media(max-width: 420px) {
  .home-container3 {
    justify-content: center;
  }
  .home-container4 {
    justify-content: center;
    padding-bottom: 102px;
  }
}

.sponsor-block{
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  background-color:#FFFFF;
  margin: 8px;
  padding: 4px;
  
}

.Sponsor-text{
  font-size: 14px;
}

.eracontainer {
  display: flex; /* Display items in a flex container for horizontal layout */
  flex-wrap: wrap; /* Allow items to wrap to the next line */
  justify-content: space-between; /* Distribute space between items */
  width: 100%; /* Make the container full width */
}

/* For smaller screens, ensure the items wrap properly */
@media (max-width: 550px) {

  .home-container{
    
  }
  .eracontainer {
    flex-direction: column;
    margin-bottom: 200px;
  }
}

@media (max-width: 550px) {
  .home-text {
    font-size: 14px;
    white-space: wrap;
  }

  .home-text3 {
    font-size: 14px;
    white-space: wrap;
  }
}