.dthroof-container {
  width: 100%;
  height: 77px;
  display: flex;
  position: relative;
  align-items: center;
  margin-bottom: 8px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: #000000;
  
}

.dthroof-container1 {
  flex: 0 0 auto;
  width: 15%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dthroof-icon {
  fill: #fff6f6;
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
}

.dthroof-container2 {
  flex: 0 0 auto;
  width: 70%;
  height: 100%;
  display: flex;
  align-items: center;
  border-color: #787878;
  border-style: dashed;
  border-width: 0px;
  flex-direction: column;
  justify-content: center;
}

.dthroof-text {
  color: #030303;
  padding: var(--dl-space-space-halfunit);
  background-color: #030303;
  font-weight: 600
}

.dthroof-container3 {
  flex: 0 0 auto;
  width: 15%;
  height: 100%;
  display: flex;
  align-items: center;
  border-color: rgba(120, 120, 120, 0);
  border-style: dashed;
  border-width: 0px;
  justify-content: center;
}

.dthroof-icon3 {
  fill: #ffffff;
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
}

@media (max-width: 991px) {
  .dthroof-container {
    height: 65px;
  }
}

@media (max-width: 420px) {
  .dthroof-container {
    width: 419px;
    height: 77px;
  }
  .dthroof-text {
    font-size: 12px;
    text-align: center;
  }
  .dthroof-icon3 {
    fill: #ffffff;
  }
}